import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getDuration } from '../../../base/functions/Functions';
import { AppState } from '../../../global-state/store/root-reducers';
import { getSideBarSectionContent } from '../../../global-state/actions/student-training-actions';
import ChapterSidebarComponent from './sidebar-component/chapter-sidebar-component';
import ExamSidebarComponent from './sidebar-component/exam-sidebar-component';
import DiplomaSidebarComponent from './sidebar-component/diploma-sidebar-component';
import { closeModal, openModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../../modals/modal-ids';
import CustomSwipeableDrawer from './chapter-course-mobile';
import { getChapterTrainingIndex } from '../../../services/training.service';

const PathCourseSidebarComponent = (props: any) => {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation();
    let course = useMemo(() => { return props?.course }, [props?.course]);
    const [title, setTitle] = useState<string>("");
    const [CoursePour, setCoursePour] = useState<number>(0);
    const [courseProgress, setCourseProgress] = useState<any>(undefined);
    const [openChapter, setOpenChapter] = useState<boolean>(false)
    const [contract, setContract] = useState<any>()
    const [trainingDuration, setTrainingDuration] = useState<string>("00H00")
    const [sections, setsections] = useState<any>(undefined)


    useEffect(() => {
        if ((props?.courseSections) !== (sections)) {
            setsections(props?.courseSections)
        }
    }, [props?.courseSections])


    useEffect(() => {
        if (courseProgress !== props?.currentTrainingProgress) {
            setCourseProgress(props?.currentTrainingProgress);
        }
    }, [props?.currentTrainingProgress])

    useEffect(() => {
        if (courseProgress?.progress !== 0) {
            setCoursePour(props?.currentTrainingProgress?.progress)
        } else {
            setCoursePour(0)
        }
    }, [courseProgress])

    useEffect(() => {
        if (props?.subscription !== undefined && props?.subscription !== contract) {
            setContract(props?.subscription)
        }
    }, [props?.subscription])

    useEffect(() => {
        setTitle(course?.title);
        if (typeof course?.duration === 'number') {
            setTrainingDuration(getDuration(course?.duration))
        } else {
            setTrainingDuration("00H00")
        }
    }, [course])

    const openCloseSubList = () => {
        setOpen(!open);
    }

    const displayContent = async (sectionContent: any, index: number, contentType: string) => {
        props.isMobile && setOpenChapter(true)
        const copyPorgress = { ...courseProgress };
        setCourseProgress(copyPorgress);
        if (props.isMobile) {
            await props?.getSideBarSectionContent(sectionContent, index, contentType, course?.followUp?.id);
        } else {
            sections && await props?.getContent(index)
        }
    }

    const handleOpen = () => {
        props?.openModal({ idModal: ModalIDS.DEACTIVATE_SUBSCRIPTION_MODAL, size: "md", data: { course: props?.course, contract: props?.subscription?.contract } });

    }

    return (
        <>
            <CustomSwipeableDrawer isOpen={openChapter} onClose={() => setOpenChapter(false)} />
            <div className={` ${(open === true) ? "component-chapters-progress" : "component-chapters-progress close"}`} style={props.isMobile && { width: "100%" }}>
                {!props.isMobile ? <div className="component-chapters-progress-header" >
                    <div className="component-chapters-progress-header-column d-flex">
                        <button onClick={() => openCloseSubList()} type="button" className="btn-QuickActions small d-flex my-auto">
                            {open ?
                                <span className="material-icons-outlined">arrow_back</span> :
                                <span className="material-icons-outlined">arrow_forward</span>
                            }
                        </button>
                        <div className={`body-md bold ms-2 mt-auto mb-2 ${(open === false) ? " component-chapters-progress-header-more" : "component-chapters-progress-header-more close"}`}>...</div>
                    </div>
                    <div className={` ${(open === true) ? "component-chapters-progress-header-column-info w-100" : "component-chapters-progress-header-column-info close"}`}>
                        <div className="component-chapters-progress-header-column-content">
                            <div className="d-flex  justify-content-between align-items-center w-100">
                                <div className="d-flex flex-row align-items-center">
                                    <svg className="me-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z" fill="#5693EE" />
                                        <line x1="7.94727" y1="14.7895" x2="16.0525" y2="14.7895" stroke="#5693EE" strokeWidth="2" strokeLinecap="round" />
                                        <line x1="16.0527" y1="9.21051" x2="7.94747" y2="9.21051" stroke="#5693EE" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                    <span className="H3-Headline component-chapters-progress-header-column-content-title">
                                        {t('Cours')}
                                    </span>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <div className="me-2">
                                        <span className="H4-Subtitle neutral-2">{Math.round(CoursePour)}</span>
                                        <span className="body-sm neutral-3">%</span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center">
                                        <span className="material-icons-outlined neutral-3 me-1" style={{ fontSize: 16 }}> timer </span>
                                        <span className="H4-Subtitle neutral-2" style={{ marginTop: 3 }}>{trainingDuration}</span>
                                    </div>
                                </div>
                            </div>

                            <span className="body-md black-800">
                                {title}
                            </span>
                        </div>
                    </div>
                </div> : <div className='container'>
                    <div className="d-flex  justify-content-between align-items-center w-100" style={{ marginTop: "20px", marginBottom: "20px" }}><div className="d-flex flex-row align-items-center">
                        <span className="component-chapters-progress-header-column-content-title" style={{ fontSize: "18px", fontWeight: "500" }}>Contenu</span></div><div className="d-flex flex-row align-items-center"><div className="d-flex flex-row align-items-center"><span className="material-icons-outlined neutral-3 me-1" style={{ fontSize: "16px;" }}> timer </span><span className="H4-Subtitle neutral-2" style={{ marginTop: "3px;" }}>{trainingDuration}</span></div></div></div>
                </div>}

                <div className={` p-0 ${(open === true) ? "" : "close"}`}>
                    <ul className="component-chapters-progress-chapters-list p-0">
                        {sections && sections.map((obj: any, i: number) => {

                            return (obj?.type === "CHAPTER") ? (
                                <>
                                    <ChapterSidebarComponent open={open} content={obj} index={i} indexChapter={getChapterTrainingIndex(obj.id, course?.orders)} displayContent={displayContent} isLastChapter={i + 1 === sections.length} />
                                </>) : ((obj?.type === "KNOWLEDGETEST") || (obj?.type === "FINALEXAM")) ? (<>
                                    <ExamSidebarComponent open={open} content={obj} index={i} displayContent={displayContent} />
                                </>) : (props?.course?.diploma !== null) && (
                                    <DiplomaSidebarComponent open={open} title={title} content={obj} index={i} displayContent={displayContent} overallProgress={courseProgress?.progress} />
                                )
                        }
                        )}
                    </ul>
                    {!!contract?.offerTraining && contract?.offerTraining?.period != "ONESHOT" && contract?.offerTraining?.nbPeriod == null && !!contract?.contract && contract?.contract?.contractDetails?.contractDuration?.endAt == null &&
                        <button className="btn-text outlined black-800 body-md bold ms-4" onClick={handleOpen} >      {t('cancelSubscription')}</button>
                    }
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getSideBarSectionContent,
            closeModal,
            openModal,
        },
        dispatch
    );

const mapStateToProps = (state: AppState) => ({
    course: state.studentTraining.currentCourse,
    sectionContent: state.studentTraining.sectionContentDetails,
    connectedUser: state.authReducer.connectedUser,
    currentTrainingProgress: state.studentTraining.currentTrainingProgress,
    checkIndexProgress: state.studentTraining.first,
    subscription: state.courseReducer.subscription,
});

export default connect(mapStateToProps, mapDispatchToProps)(PathCourseSidebarComponent);